import React, { Component } from "react"
import styled, { keyframes, css } from "styled-components"
import BgImg from "gatsby-background-image"
import { Link } from "gatsby"

import { colors, headlineThree, fontSizer, bodyCopy } from "../../../Utilities"

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
`

const complexMixin = css`
  animation-name: ${fadeIn};
  animation-duration: 1.5s;
  animation-delay: ${props => props.delay};
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
`

const PortfolioCardStyled = styled.div`
  position: relative;
  width: 100%;
  min-height: 30rem;
  overflow: hidden;
  transition: all 0.4s ease;
  opacity: 1;
  ${props => (props.removeAnimation === false ? complexMixin : false)}

  @media (min-width: 768px) {
    width: ${props => (props.position ? "50%" : "100%")};
    min-height: 60rem;
  }

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .port-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    transition: all 0.4s ease;
    transform: translate(-100%, -100%);

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.colorSecondary};
      opacity: 0.75;
      z-index: 1;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      padding: 5rem;
      transform: translate(-50%, -50%);
      z-index: 5;

      h2 {
        ${headlineThree};
        ${fontSizer(2, 3, 76.8, 110, 2.2)}
        margin-bottom: 0;
        width: 100%;
        color: ${colors.white};
        text-align: center;
        text-transform: uppercase;
      }

      p {
        ${bodyCopy};
        position: relative;
        width: 100%;
        margin-top: 0;
        align-self: center;
        padding: 2rem 9vw;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        z-index: 10;
      }
    }
  }

  .port-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 1;
  }

  &:hover {
    .port-title {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
`

class PortfolioCard extends Component {
  render() {
    const { port, position } = this.props
    const removeAnimation = position <= 4 ? true : false
    return (
      <PortfolioCardStyled
        position={position}
        removeAnimation={removeAnimation}
      >
        <Link to={`/portfolio/${port.slug}`}>
          <div className="port-title">
            <div className="port-title__content">
              <div>
                <h2 dangerouslySetInnerHTML={{ __html: port.title }} />
                <p>{port.acf.tag_line}</p>
              </div>
            </div>
            <div className="port-title__overlay" />
          </div>
          <BgImg
            className="port-background"
            Tag="div"
            fluid={port.acf.main_image.localFile.childImageSharp.fluid}
          />
        </Link>
      </PortfolioCardStyled>
    )
  }
}

export default PortfolioCard
