import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HeaderScene from "../components/PageParts/Portfolio/HeaderScene"
import Intro from "../components/PageParts/Portfolio/Intro"
import PortfolioList from "../components/PageParts/Portfolio/PortfolioList"

const PortfolioPage = props => {
  const { portItems, workType, metaImage } = props.data
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Switchback Creative | Our Portfolio - Some of our Work"
        description="Our Portfolio. The proof is in the pudding...so here is some of our work for you to look through. It will give you an idea of what we are capable of."
        location={props.location.pathname}
        pageImg={metaImage.publicURL}
      />
      <HeaderScene />
      <Intro />
      <PortfolioList portItems={portItems} workType={workType} />
    </Layout>
  )
}

export const portfolioQuery = graphql`
  {
    portItems: allWordpressWpPortfolio(
      sort: { fields: [date], order: [DESC] }
    ) {
      edges {
        node {
          slug
          title
          work_type
          acf {
            tag_line
            main_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    workType: allWordpressWpWorkType {
      edges {
        node {
          name
          wordpress_id
          count
        }
      }
    }
    metaImage: file(relativePath: { eq: "switchback-whatwedo-meta.jpg" }) {
      publicURL
    }
  }
`

export default PortfolioPage
