/* eslint-disable */
import React, { Component } from "react"
import styled from "styled-components"
import { striptCallOut, colors, fontSizer, buttonTwo } from "../../../Utilities"
import PortfolioCard from "./PortfolioCard"

const PortfolioListSection = styled.section`
  padding-top: 1rem;

  @media (min-width: 768px) {
    padding-top: 5rem;
  }

  .port-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  .port-more {
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;

    button {
      ${buttonTwo};
      background: #fff;

      &:disabled {
        border: solid 0.2rem ${colors.black};
        color: ${colors.black};
        opacity: 0.25;
      }
    }
  }
`

const PortfolioNavigation = styled.div`
  width: 100%;
  margin: 0 auto 3rem;
  text-align: center;

  @media (min-width: 768px) {
    margin: 5rem auto 3rem;
  }

  button {
    ${striptCallOut};
    ${fontSizer(2, 3, 76.8, 160, 2.4)}
    display: block;
    margin: 0 auto 2rem;
    position: relative;
    padding: 0.5rem 1rem;
    transition: all 0.3s ease-in-out;
    background: transparent;
    border: none;
    font-weight: 300;

    @media (min-width: 768px) {
      display: inline-block;
      margin: 0 auto;
      border-left: solid 0.3rem ${colors.black};
    }

    &:first-of-type {
      border: none;
    }

    &::after {
      position: absolute;
      right: 0.5rem;
      bottom: 1rem;
      left: 0.5rem;
      height: 1rem;
      transition: all 0.3s ease-in-out;
      background: ${colors.colorSecondary};
      content: "";
      opacity: 0;
      z-index: -1;
    }

    &:focus,
    &:hover {
      outline: none;
      cursor: pointer;

      &::after {
        opacity: 1;
      }
    }

    &.active-button {
      &::after {
        opacity: 1;
      }
    }
  }
`

export default class PotfolioList extends Component {
  constructor(props) {
    super(props)
    this.loadMore = this.loadMore.bind(this)
    this.chooseAllCats = this.chooseAllCats.bind(this)
    this.chooseBrand = this.chooseBrand.bind(this)
    this.chooseWeb = this.chooseWeb.bind(this)
    this.chooseMarket = this.chooseMarket.bind(this)
    this.state = {
      allPorts: [],
      allCats: [],
      allBrand: [],
      allMarket: [],
      allWeb: [],
      curentCat: 0,
      countSkip: 6,
      countCurrent: 5,
      countMax: 5,
    }
  }

  componentDidMount() {
    const allBrand = this.props.portItems.edges.filter(port => {
      if (
        port.node.work_type[0] ===
        this.props.workType.edges[0].node.wordpress_id
      ) {
        return port
      }
    })
    const allMarket = this.props.portItems.edges.filter(port => {
      if (
        port.node.work_type[0] ===
        this.props.workType.edges[1].node.wordpress_id
      ) {
        return port
      }
    })
    const allWeb = this.props.portItems.edges.filter(port => {
      if (
        port.node.work_type[0] ===
        this.props.workType.edges[2].node.wordpress_id
      ) {
        return port
      }
    })

    this.setState(prevState => {
      return {
        ...prevState,
        allPorts: this.props.portItems.edges,
        allCats: this.props.workType.edges,
        allBrand: allBrand,
        allMarket: allMarket,
        allWeb: allWeb,
        countMax: this.props.portItems.edges.length,
      }
    })
  }

  chooseAllCats() {
    if (this.state.curentCat === 0) return
    this.setState(prevState => {
      return {
        ...prevState,
        curentCat: 0,
        countSkip: 6,
        countCurrent:
          this.state.allPorts.length <= 5 ? this.state.allPorts.length : 5,
        countMax: this.state.allPorts.length,
      }
    })
  }

  chooseBrand() {
    if (this.state.curentCat === 189) return
    this.setState(prevState => {
      return {
        ...prevState,
        curentCat: 189,
        countSkip: 6,
        countCurrent:
          this.state.allBrand.length <= 5 ? this.state.allBrand.length : 5,
        countMax: this.state.allBrand.length,
      }
    })
  }

  chooseWeb() {
    if (this.state.curentCat === 187) return
    this.setState(prevState => {
      return {
        ...prevState,
        curentCat: 187,
        countSkip: 6,
        countCurrent:
          this.state.allWeb.length <= 5 ? this.state.allWeb.length : 5,
        countMax: this.state.allWeb.length,
      }
    })
  }

  chooseMarket() {
    if (this.state.curentCat === 188) return

    this.setState(prevState => {
      return {
        ...prevState,
        curentCat: 188,
        countSkip: 6,
        countCurrent:
          this.state.allMarket.length <= 5 ? this.state.allMarket.length : 5,
        countMax: this.state.allMarket.length,
      }
    })
  }

  loadMore() {
    const newMax = this.state.countCurrent + this.state.countSkip
    const newCurrent =
      newMax <= this.state.countMax ? newMax : this.state.countMax

    this.setState(prevState => {
      return {
        ...prevState,
        countCurrent: newCurrent,
      }
    })
  }

  render() {
    const disabled =
      this.state.countCurrent >= this.state.countMax ? true : false
    return (
      <PortfolioListSection>
        <div className="port-wrapper">
          <PortfolioNavigation>
            <button
              className={this.state.curentCat === 0 ? "active-button" : ""}
              onClick={this.chooseAllCats}
            >
              All Projects
            </button>
            <button
              className={this.state.curentCat === 189 ? "active-button" : ""}
              onClick={this.chooseBrand}
            >
              Design + Branding
            </button>
            <button
              className={this.state.curentCat === 187 ? "active-button" : ""}
              onClick={this.chooseWeb}
            >
              Website Development
            </button>
            <button
              className={this.state.curentCat === 188 ? "active-button" : ""}
              onClick={this.chooseMarket}
            >
              Marketing + Advertising
            </button>
          </PortfolioNavigation>
          {this.state.curentCat === 0 &&
            this.state.allPorts.map((port, index) => {
              if (index >= this.state.countCurrent) return
              return (
                <PortfolioCard key={index} position={index} port={port.node} />
              )
            })}

          {this.state.curentCat === 189 &&
            this.state.allBrand.map((port, index) => {
              if (index >= this.state.countCurrent) return
              return (
                <PortfolioCard key={index} position={index} port={port.node} />
              )
            })}

          {this.state.curentCat === 187 &&
            this.state.allWeb.map((port, index) => {
              if (index >= this.state.countCurrent) return
              return (
                <PortfolioCard key={index} position={index} port={port.node} />
              )
            })}

          {this.state.curentCat === 188 &&
            this.state.allMarket.map((port, index) => {
              if (index >= this.state.countCurrent) return
              return (
                <PortfolioCard key={index} position={index} port={port.node} />
              )
            })}
          <div className="port-more">
            <button disabled={disabled} onClick={this.loadMore}>
              {disabled === false ? "More Work" : "No More Work"}
            </button>
          </div>
        </div>
      </PortfolioListSection>
    )
  }
}
